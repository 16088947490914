import React, { FC } from "react";
import Layout from "../components/layout/Layout";
import { graphql, PageProps } from "gatsby";
import GalleryGrid from "../components/ui/GalleryGrid";
import t from "../i18n"
import HeadMeta from "../components/layout/HeadMeta";
import { TypeImage, TypeLocale } from "../types";

type GalleryData = {
    allFile: { images: TypeImage[] }
}
type GalleryContext = {
    locale: TypeLocale
}

const Gallery: FC<PageProps<GalleryData, GalleryContext>> = ( { data } ) => {
    return (
        <Layout>
            <HeadMeta title={ t( "Gallery" ) }/>
            <h1 className={ "mb--md text--center" }>{ t( "Gallery" ) }</h1>
            <GalleryGrid images={ data.allFile.images }/>
        </Layout>
    )
}

export default Gallery

export const query = graphql`{
    allFile(
        sort: {fields: name}
        filter: {extension: {regex: "/jpeg|jpg|png|gif/"}, relativeDirectory: {in: ["exterior", "interior"]}}
    ) {
        images: nodes {
            relativePath
            childImageSharp {
                original {
                    src
                }
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
}`
